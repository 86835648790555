import request from '@/utils/request'

export function fetchConfiger(params) {
  return request({
    url: '/FlowConfiger/Search',
    method: 'post',
    data: params
  });
}


export function delConfiger(id) {
  return request({
    url: '/FlowConfiger/' + id,
    method: 'delete'
  });
}


export function submitConfiger(params) {
  return request({
    url: '/FlowConfiger/' + (params.id || ''),
    method: params.id ? 'put' : 'post',
    data: params || {}
  });
}


export function fetchConfigerDetail(id) {
  return request({
    url: '/FlowConfiger/' + id,
    method: 'get'
  });
}

export function fetchConfigerByCode(flowCode) {
  return request({
    url: '/FlowConfiger/Code/' + flowCode,
    method: 'get'
  });
}

export function getBillCode(FormType) {
  return request({
    url: '/FlowInstance/GetBillCode?FormType=' + (FormType || ''),
    method: 'get'
  });
}

export function submitInstance(params) {
  return request({
    url: '/FlowInstance/' + (params.id || ''),
    method: params.id ? 'put' : 'post',
    data: params || {}
  });
}

export function returnInstance(id) {
  return request({
    url: '/FlowInstance/Return/' + (id || ''),
    method: 'post'
  });
}


export function fetchInstance(params) {
  return request({
    url: '/FlowInstance/'+ (params.method || 'Search'),
    method: 'post',
    data: params
  });
}


export function getInstance(id) {
  return request({
    url: '/FlowInstance/' + id,
    method: 'get'
  });
}

export function deleteInstance(id) {
  return request({
    url: '/FlowInstance/' + id,
    method: 'delete'
  });
}

export function fetchHistoryInstance(params, status, nodeName) {
  return request({
    url: '/FlowInstance/History?status=' + (status || 0) + '&nodeName=' + (nodeName || ''),
    method: 'post',
    data: params
  });
}

export function fetchRecords(instanceId) {
  return request({
    url: '/FlowRecords/?instanceId=' + instanceId,
    method: 'get'
  });
}

export function getSuggestUser(flowCode, targetNodeId) {
  return request({
    url: '/FlowInstance/GetSuggestUser?flowCode=' + (flowCode) + '&targetNodeId=' + (targetNodeId),
    method: 'get'
  });
}

export function getSuggestBank(flowCode) {
  return request({
    url: '/FlowInstance/GetSuggestBank?flowCode=' + (flowCode),
    method: 'get'
  });
}
